import { Category, EssayStatus } from "@modules/Essay/store"
import dayjs from "dayjs"
import { Link } from "gatsby"
import React from "react"

import { ActionButton } from "./components/ActionButton"

export const tableColumns = ({ setErrors, onClick, loading }) => [
  {
    title: "Nome do ensaio",
    dataIndex: "name",
    key: "name",
    width: "165px",
    render: (__text, { id }) => {
      const path = `/admin/ensaios/editar/${id.toString()}`

      return (
        <Link to={path}>
          <span className="modelNameColor">{__text}</span>
        </Link>
      )
    },
  },
  {
    title: "Modelo",
    dataIndex: "model",
    key: "model",
    width: "350px",
    align: "center",
  },
  {
    title: "Fotógrafo",
    dataIndex: "photographer",
    key: "photographer",
    width: "350px",
    align: "center",
  },
  {
    title: "Publicação",
    dataIndex: "schedulePosting",
    key: "schedulePosting",
    hideInSearch: true,
    width: "150px",
    align: "center",
    render: (record) => (
      <span>
        {dayjs(record).isValid()
          ? dayjs(record).format("DD/MM/YYYY HH:mm")
          : "-"}
      </span>
    ),
  },
  {
    title: "Categoria",
    dataIndex: "category",
    key: "category",
    width: "100px",
    align: "center",
    valueEnum: {
      1: { text: "Nude", value: Category.NUDE },
      2: { text: "Topless", value: Category.TOPLESS },
      3: { text: "Lingerie", value: Category.LINGERIE },
    },
    onFilter: (value, record) => record.category === value,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "100px",
    align: "center",
    valueEnum: {
      0: { text: "Deletado", value: EssayStatus.DELETED, status: "deleted" },
      1: { text: "Ativo", value: EssayStatus.ACTIVE, status: "success" },
      2: { text: "Inativo", value: EssayStatus.INACTIVE, status: "default" },
      3: {
        text: "Agendado",
        value: EssayStatus.SCHEDULE,
        status: "processing",
      },
    },
    onFilter: (value, record) => record.status === value,
  },
  {
    title: "",
    dataIndex: "",
    key: "action",
    hideInSearch: true,
    width: "100px",
    align: "center",
    render: (__text, { id, status }) => {
      return (
        <ActionButton
          status={status}
          id={id}
          setErrors={setErrors}
          onClick={onClick}
          loading={loading}
        />
      )
    },
  },
]
