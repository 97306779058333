import { LayoutAdmin } from "@components/LayoutAdmin"
import { useStores } from "@hooks/use-stores"
import { ListEssay } from "@modules/Essay/Admin/components/ListEssay"
import _get from "lodash/get"
import React, { useCallback, useEffect, useState } from "react"

const EssayPage = () => {
  const [isClient, setIsClient] = useState(false)
  const [allEssay, setAllEssay] = useState([])

  const { essay } = useStores()

  const { findAll } = essay

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleGetAllEssay = useCallback(async (params) => {
    params.orderBy = "createdDate"

    const res = await findAll(params)

    const data = _get(res, "data", [])

    setAllEssay(data)
  }, [])

  if (!isClient) return null

  return (
    <div className="vh-100 session-page">
      <LayoutAdmin title="Ensaios" subTitle="Visualize e gerencie os ensaios.">
        <ListEssay data={allEssay} getData={handleGetAllEssay} />
      </LayoutAdmin>
    </div>
  )
}

export default EssayPage
