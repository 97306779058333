import { CustomModalErrors } from "@components/CustomModalErrors"
import CustomProTable from "@components/CustomProTable"
import { useStores } from "@hooks/use-stores"
import { tableColumns } from "@modules/Essay/Admin/tableColumns"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import { Button } from "antd"
import { navigate } from "gatsby"
import _get from "lodash/get"
import React, { useRef, useState } from "react"

export const ListEssay = ({ data, getData }) => {
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const { essay } = useStores()

  const actionRef = useRef(null)

  const { publish } = essay

  const handleClick = async ({ action, id }) => {
    setLoading(true)

    const res = await publish({
      essayId: id,
      action,
    })

    setLoading(false)

    const errorMessage = _get(res, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }

    actionRef.current.reload()
  }

  return (
    <div>
      <CustomProTable
        actionRef={actionRef}
        headerTitle="Listagem de ensaios"
        columns={tableColumns({ setErrors, onClick: handleClick, loading })}
        request={getData}
        dataSource={data}
        toolBarRender={() => [
          <Button
            key="button"
            type="primary"
            className="activeButton w-100"
            onClick={() => navigate("/admin/ensaios/cadastrar")}
          >
            Cadastrar ensaio
          </Button>,
          <Button
            key="button"
            type="outline"
            className="activeButton w-100"
            onClick={() => actionRef.current.reload()}
          >
            Atualizar
          </Button>,
        ]}
        rowKey={(record) => record.id}
      />

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}
    </div>
  )
}
