import { EssayActions, EssayStatus } from "@modules/Essay/store"
import { Button } from "antd"
import cx from "classnames"
import React from "react"

export const ActionButton = ({ id, status, onClick, loading }) => {
  const buttons = {
    [EssayStatus.ACTIVE]: {
      text: "Desativar",
      className: "desactiveButton",
      type: "outline",
      action: EssayActions.DEACTIVATE,
    },
    [EssayStatus.INACTIVE]: {
      text: "Ativar",
      className: "activeButton",
      type: "primary",
      action: EssayActions.ACTIVATE,
    },
    [EssayStatus.SCHEDULE]: {
      text: "Publicar",
      className: "publishButton",
      type: "default",
      action: EssayActions.PUBLISH,
    },
  }

  if (status === EssayStatus.DELETED) {
    return null
  }

  return (
    <Button
      type={buttons[status].type}
      className={cx("tableButtons", buttons[status].className)}
      onClick={() => {
        onClick({
          action: buttons[status].action,
          id,
        })
      }}
      loading={loading}
      style={{ width: "115px" }}
    >
      {buttons[status].text}
    </Button>
  )
}
